import React, {useCallback, useEffect, useRef, useState} from 'react'
import ModalWindow from "../../../UI/ModalWindow";
import {useDispatch, useSelector} from "react-redux";
import {createBrands, removePageFromUser, requestBrands, updateBrands} from "../../../../actions/requests";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {defaultNeedsPrompt, defaultSystemInstruction, getIcon} from "../../../../constants";
import PagesSelectionItem from "../../../UI/PagesSelectionItem";
import Button from "../../../UI/Button";
import {addPage, initCurrentPages, removePage, setSelectedBrand} from "../../../../actions/brands";
import BrandsPage from "./BrandsPage";
import {setShowBrandsModal} from "../../../../actions/ui";
import {faArrowLeft} from "@awesome.me/kit-a13c95776b/icons/modules/sharp/thin";
import SwitchItem from "../../../UI/SwitchItem";
import FeedbackItem from "./FeedbackItem";

const BrandsModal = (props) => {
    const showBrandsModal = useSelector(state => state.ui.showBrandsModal)
    const brand = useSelector(state => state.brands.selectedBrand)
    const {name, info, enabled, system_instruction, ai_trigger, delay, delay_type, delay_min, mention, tone, tone_style,
        auto_reply, needs_reply_prompt} = brand.attributes || {system_instruction: defaultSystemInstruction, needs_reply_prompt: defaultNeedsPrompt}
    const [enableTone, setEnableTone] = useState(tone || false)
    const [enableMention, setEnableMention] = useState(mention || false)
    const [enableDelay, setEnableDelay] = useState(delay)
    const [showDelayTime, setShowDelayTime] = useState(delay_type == "fixed")
    const [aiTrigger, setAiTrigger] = useState(ai_trigger || "needs_reply")
    const [autoReply, setAutoReply] = useState(auto_reply || false)
    const dispatch = useDispatch()
    const brandForm = useRef()
    const btnRef = useRef()

    const handleAutoReply = (e) => {
        setAutoReply(e.currentTarget.checked)
    }

    const handleAiTrigger = (e) => {
        setAiTrigger(e.currentTarget.value)
    }

    const changeTone = (e) => {
        setEnableTone(e.currentTarget.checked)
    }

    const changeMention = (e) => {
        setEnableMention(e.currentTarget.checked)
    }

    const changeDelay = (e) => {
        setEnableDelay(e.currentTarget.checked)
    }

    const delayTypeHandler = (e) => {
        if(e.target.value == "fixed")
        {
            setShowDelayTime(true)
        }else{
            setShowDelayTime(false)
        }
    }

    const handleClose = () => {
        dispatch(setSelectedBrand())
        dispatch(setShowBrandsModal())
    }

    useEffect(()=>{
        if (showBrandsModal) {
            dispatch(requestBrands(true))
        }
    },[showBrandsModal])

    const handleBrandForm = (e) => {
        e.preventDefault()
        const formData = new FormData(brandForm.current)

        if(brand.attributes){
            dispatch(updateBrands(brand.id, formData))
        }else{
            dispatch(createBrands(formData))
        }
        handleClose()
    }



    const isValidForm = useCallback(e => {
        // let valid = false
        // const formData = new FormData(brandForm.current)
        //
        // for(let pair of formData.entries()) {
        //     if(!pair[1]) {
        //         valid = true
        //     }
        // }
        //
        // btnRef.current.disabled = false
    }, [])

    return (
        <>
            {showBrandsModal && <div className="agents">
                <button type="reset" className="btn btn-tr-bl mb-4 pl-0" onClick={() => handleClose()}>
                    <FontAwesomeIcon icon={faArrowLeft} className="mr-2"/>
                    Go back
                </button>

                {/*<h4 className="mb-4">{brand ? 'Edit' : 'Add new'} agent</h4>*/}

                <form onSubmit={handleBrandForm} ref={brandForm}>
                    <input type="hidden" name="brand[enabled]" value={enabled}/>
                    {!autoReply && <input type="hidden" name="brand[auto_reply]" value="false"/>}
                    {!enableMention && <input type="hidden" name="brand[mention]" value="false"/>}
                    {!enableTone && <input type="hidden" name="brand[tone]" value="false"/>}
                    {!enableDelay && <input type="hidden" name="brand[delay]" value="false"/>}
                    <div className="main row">
                        <div className="form-group mb-3">
                            <label>Name</label>
                            <input type="text" name="brand[name]"
                                   defaultValue={name}
                                   className="form-control"
                                   // required="required"
                                   onChange={isValidForm}/>
                        </div>

                        <BrandsPage />

                        <div className="form-group mb-3">
                            <label>Instructions</label>
                            <textarea type="text" name="brand[system_instruction]"
                                      defaultValue={system_instruction}
                                      className="form-control"
                                      rows="8"
                                      maxLength="100000"
                                // required="required"
                                      onChange={isValidForm}/>
                        </div>

                        <div className="form-group mb-3">
                            <label>Knowledge</label>
                            <textarea type="text" name="brand[info][knowledge]"
                                   defaultValue={info && info.knowledge}
                                   className="form-control"
                                      rows="8"
                                      maxLength="100000"
                                      placeholder="Add information and FAQs about your company, products or services"
                                   // required="required"
                                   onChange={isValidForm}/>
                        </div>

                        <div className="form-group mb-3">
                            <label>Intent detection</label>
                            <div className="nrt mb-2">
                                <span>The prompt that our AI uses to determine whether a comment is labeled as </span>
                                <div className='nr-b badge badge-light-green ml-1'>
                                    Needs reply
                                </div>
                            </div>

                            <input type="text" name="brand[needs_reply_prompt]"
                                   defaultValue={needs_reply_prompt}
                                   className="form-control"
                                   onChange={isValidForm}/>
                        </div>

                        <div className="form-group mb-3">
                            <label>Agent</label>
                            <SwitchItem title="AI auto-replies"
                                        wrapperClass="form-group mt-2 mb-0"
                                        value={autoReply}
                                        checked={autoReply}
                                        changeHandler={handleAutoReply}
                                        desc="When enabled, the AI agent will automatically reply to your comments"
                                        name="brand[auto_reply]">
                            </SwitchItem>
                        </div>

                        {autoReply && <>
                            <div className="form-group mb-3">
                                <label>Reply to</label>
                                <select className="form-select" name="brand[ai_trigger]" defaultValue={aiTrigger}
                                        onChange={handleAiTrigger} required="required">
                                    <option value="needs_reply">Automated AI replies to comments that need a reply</option>
                                    <option value="visible">Automated AI replies to all visible comments</option>
                                </select>
                            </div>

                            <SwitchItem title="Add mention"
                                          value={enableMention}
                                          checked={enableMention}
                                          wrapperClass="form-group mb-3"
                                          name="brand[mention]"
                                          desc="Mention the user who commented so he/she gets notified"
                                          changeHandler={changeMention}>
                            </SwitchItem>

                            <SwitchItem title="Tone of voice"
                                        value={enableTone}
                                        checked={enableTone}
                                        wrapperClass="form-group mb-3"
                                        name="brand[tone]"
                                        desc="Choose a tone of voice that matches your brand"
                                        changeHandler={changeTone}>
                                {enableTone && (<>
                                    <div className="mlm-2_5 mt-2 form-group mb-2">
                                        <select className="form-select" defaultValue={tone_style} name="brand[tone_style]">
                                            <option value="professional">Professional</option>
                                            <option value="funny">Funny</option>
                                            <option value="conversational">Conversational</option>
                                            <option value="casual">Casual</option>
                                            <option value="helpful">Helpful</option>
                                        </select>
                                    </div>
                                </>)}
                            </SwitchItem>

                            <SwitchItem title="Send with a delay"
                                        value={enableDelay}
                                        wrapperClass="form-group mb-3"
                                        checked={enableDelay}
                                        name="brand[delay]"
                                        desc="Send auto-replies with a random or fixed delay"
                                        changeHandler={changeDelay}>

                                {enableDelay && (<>
                                    <div className="mlm-2_5 mt-2 form-group mb-2">
                                        <select className="form-select" defaultValue={delay_type} name="brand[delay_type]"
                                                onChange={delayTypeHandler}>
                                            <option value="random">Random delay of up to 10 minutes</option>
                                            <option value="fixed">Set fixed time delay</option>
                                        </select>
                                    </div>
                                    {showDelayTime && <div className="mlm-2_5 col-lg-3 mb-2">
                                        <div className="input-group mb-3">
                                            <input type="number" name="brand[delay_min]" min={1} max={600} step={1}
                                                   defaultValue={delay_min || 1} className="form-control"  aria-describedby="basic-addon2"/>
                                            <span className="input-group-text" id="basic-addon2">minutes</span>
                                        </div>
                                    </div>}
                                </>)}

                            </SwitchItem>
                        </>}

                    </div>

                    <div className="row mt-4">
                        <div>
                            <button type="submit" className="btn btn-primary float-end" ref={btnRef}>Save</button>
                            <button type="reset" className="btn btn-secondary float-end mx-2"
                                    onClick={() => handleClose()}>Cancel</button>
                        </div>
                    </div>
                </form>

            </div>}
        </>
    )
}

export default React.memo(BrandsModal)