import {BRANDS_ACTIONS} from "../constants";

export const requestBrandsSuccess = (data) => {
    return {
        type: BRANDS_ACTIONS.REQUEST_BRANDS,
        payload: { data }
    }
}

export const addBrand = (data) => {
    return {
        type: BRANDS_ACTIONS.ADD_BRANDS,
        payload: { data }
    }
}

export const editBrand  = (brandId, attributes) => {
    return {
        type: BRANDS_ACTIONS.UPDATE_BRANDS,
        payload: { brandId, attributes }
    }
}

export const removeBrand  = (brandId) => {
    return {
        type: BRANDS_ACTIONS.REMOVE_BRANDS,
        payload: { brandId }
    }
}

export const removeFeedback  = (feedbackId) => {
    return {
        type: BRANDS_ACTIONS.REMOVE_FEEDBACK,
        payload: { feedbackId }
    }
}

export const requestPages = (data) => {
    return {
        type: BRANDS_ACTIONS.INIT_PAGES,
        payload: { data }
    }
}

export const addPage = (data) => {
    return {
        type: BRANDS_ACTIONS.ADD_PAGES,
        payload: { data }
    }
}

export const removePage  = (pageId) => {
    return {
        type: BRANDS_ACTIONS.REMOVE_PAGES,
        payload: { pageId }
    }
}

export const initCurrentPages = (data) => {
    return {
        type: BRANDS_ACTIONS.INIT_CURRENT_PAGES,
        payload: { data }
    }
}

export const addCurrentPage = (data) => {
    return {
        type: BRANDS_ACTIONS.ADD_CURRENT_PAGES,
        payload: { data }
    }
}

export const removeCurrentPage  = (pageId) => {
    return {
        type: BRANDS_ACTIONS.REMOVE_CURRENT_PAGES,
        payload: { pageId }
    }
}

export const setSelectedBrand = (selectedBrand={}) => {
    return {
        type: BRANDS_ACTIONS.SET_SELECTED_BRAND,
        payload: {selectedBrand}
    }
}