import React from 'react';

const NotificationBadge  = ({expire_in, approached_comments_limit, has_app_errors}) => {

    let message = ""
    let href = "/account-settings#account"
    if(approached_comments_limit) {
        message = 'Your usage approaches your plan limit'
    }
    if(has_app_errors){
        message = "We've lost access to your account"
        href = "/account-settings#pages"
    }
    else if(expire_in === 0){
        message = "Your free trial has expired"
    }else if(expire_in > 0){
        message = `Your free trial expires in ${expire_in} days`
    }

    return (
        <>
            <a className="trial-notice" href={href}>
                {message}
            </a>
        </>
    )
}

export default React.memo(NotificationBadge)