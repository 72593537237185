import React from 'react'
import Button from "../../Button"
import axios from "axios"
import { CSRF } from "../../../../constants"
import { errorNotify } from "../../../../utils/app"

export const ImportSection = ({ pageId }) => {
  const startImport = (e) => {
    e.preventDefault()

    axios({
      method: 'post',
      url: `/main/start_import`,
      data: { page_id: pageId },
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': CSRF
      }
    }).catch(errors => {
      errorNotify(errors.response.data.error)
    })
  }

  return (
    <>
      <div className="mt-4" />
      <div className="settings-item">
        <div className="switch-desc">
          <div className="switch-desc-heading">
            Import
          </div>
          <div className="d-flex justify-content-between">
            Import the last 7 days of comments and process these according to your CommentGuard moderation settings.
            <Button
              name="button"
              className="btn btn-primary import-btn"
              clickHandler={startImport}
            >
              Import and process comments
            </Button>
          </div>
        </div>
      </div>
    </>
  )
} 