import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import "./Brands.scss"
import Button from "../../../UI/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getIcon} from "../../../../constants";
import {faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";
import {faQuoteRight} from "@awesome.me/kit-a13c95776b/icons/modules/sharp/solid";
import {deleteReplyFeedback} from "../../../../actions/requests";

const FeedbackItem = ({feedback}) => {
    const dispatch = useDispatch()
    const {instructions, comment_text, created_at} = feedback.attributes

    // const editBrandModal = (brand) => {
    //     dispatch(setSelectedBrand(brand))
    //     dispatch(setShowBrandsModal())
    // }
    //
    const deleteFeedback = (feedbackId) => {
        console.log('click')
        dispatch(deleteReplyFeedback(feedbackId))
    }

    // const enableBrand = (e, id) => {
    //     e.preventDefault()
    //     const formData = new FormData()
    //     formData.append("brand[enabled]", e.target.checked)
    //     dispatch(updateBrands(id, formData))
    // }

    return (
        <div className="feedback-item" key={feedback.id}>
            <div className="f-wr">
                <div className="text">
                    {comment_text}
                </div>
                <div className="instruct">
                    {/*<div>*/}
                    {/*<div><FontAwesomeIcon icon={faQuoteRight} className="fa-fw"/></div>*/}
                    {/*<div></div>*/}
                    {/*</div>*/}
                    {/*<div>{instructions}</div>*/}
                    <blockquote className="blockquote blockquote--classic">
                        <p>{instructions}</p>
                    </blockquote>
                </div>
                <div className="date">{created_at}</div>
            </div>
            <div className="b-wr" onClick={()=>deleteFeedback(feedback.id)}>
                Delete
            </div>
        </div>
    )
}

export default React.memo(FeedbackItem)