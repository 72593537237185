import React, {useEffect, useState} from 'react'
import {faThumbsUp, faRotate} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import axios from "axios";
import {CSRF} from "../../../constants";
import {errorNotify} from "../../../utils/app";
import {editMessage} from "../../../actions/direct_messages";
import {useDispatch} from "react-redux";
import {faCircleExclamation} from "@awesome.me/kit-a13c95776b/icons/modules/classic/regular";
import Button from "../Button";
import ReactTooltip from "react-tooltip";

const Item = ({dm, pageId}) => {
    const dispatch = useDispatch()
    const {reply, message, attachment_type, attachment_url, attachment_expired, has_error, error_msg} = dm.attributes
    const [load, setLoad] = useState(false)

    useEffect(()=>{
        ReactTooltip.rebuild()
    },[error_msg])

    const reloadAttachment = (e) => {
        e.preventDefault()
        setLoad(true)

        axios({
            method: 'post',
            url: `/pages/${pageId}/direct_messages/${dm.id}/reload_attachment`,
            headers: { 'Content-Type': 'application/json',
                'X-CSRF-Token': CSRF}
        }).then(response => {
            setLoad(false)
            dispatch(editMessage(response.data.data.id, response.data.data.attributes))
        }).catch(error => {
                setLoad(false)
            }
        );
    }

    const renderMessage = () => {
        switch(attachment_type) {

            case "like":   return <FontAwesomeIcon icon={faThumbsUp} className="fa-fw"/>;
            case "image":   return <img className="msg-image" src={attachment_url} />;
            case "audio":   return <audio controls src={attachment_url}></audio>;
            default:      return <>{message}</>
        }
    }

    return (
        <div className={`message-w ${reply ? 'out' : ''}`}>
            <div className={`message ${reply ? 'out' : ''} ${attachment_type && !attachment_expired ? 'attachment' : ''}`} >
                {(attachment_expired && attachment_type != "fallback") ?
                    <div className="expired-attach">
                        <div onClick={reloadAttachment}><FontAwesomeIcon icon={faRotate} spin={load} className="fa-fw"/></div>
                        <span>Reload {attachment_type}</span>
                    </div>
                    : renderMessage()}

            </div>
            {has_error && <div className='msg-error'>
                <FontAwesomeIcon data-tip={error_msg} icon={faCircleExclamation} className="fa-fw"/>
            </div>}
            <ReactTooltip effect="solid" html={false} className="switch-tooltip"/>

        </div>
    )
}

export default React.memo(Item)