import React, {useRef, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {deleteUserSavedReplies, requestPageComments, requestUserSavedReplies} from "../../../actions/requests";
import Button from "../Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";
import {changeFilter} from "../../../actions/pages";
import {setShowSearch} from "../../../actions/ui";


const SavedRepliesList = ({setTabIndex,setSelectedReply,setShowSavedReplies,setDisabledRepliesList,setMessage, pageId}) => {
    const dispatch = useDispatch()
    const saved_replies = useSelector(state => state.user.saved_replies)
    const [searchText, setSearchText] = useState("")
    const searchRef = useRef()


    const handleEdit = (reply) =>{
        setSelectedReply(reply)
        setDisabledRepliesList(true)
        setTabIndex(1)
    }

    const handleDelete = (ReplyId) =>{
        dispatch(deleteUserSavedReplies(ReplyId, pageId))
    }

    const handleReplySelect = (message) => {
        setMessage(message)
        setShowSavedReplies(false)
    }

    const comments_search = (e) => {
        const value = e.target.value
        setSearchText(value)
        dispatch(requestUserSavedReplies(pageId, value))
    }

    const closeSearch = (e) => {
        setSearchText("")
        dispatch(requestUserSavedReplies(pageId, ""))
        searchRef.current.value = ""
    }

    return (
        <>
            <div className="mt-4 mb-2">
                Here you'll find an overview of your saved replies.
            </div>


            <div className="input-group c-search c-search-sr mb-4">
                  <span className="input-group-text c-search-icon" id="basic-addon1"><FontAwesomeIcon
                      icon={faMagnifyingGlass} className="fa-fw"/></span>
                <input type="text" className="form-control c-search-inp" id="search-inp" placeholder="Search" aria-label="Search"
                       aria-describedby="basic-addon1" onKeyUp={e=>comments_search(e)} ref={searchRef}/>
                {searchText && <span className="input-group-text c-search-icon c-search-icon-close c-search-icon-close-sr" onClick={closeSearch}>Clear</span>}
            </div>


            <div className="replies-list mb-4">
                <div className="line"></div>
                {saved_replies.length > 0 ? saved_replies.map((saved_reply)=>{
                    return  <div key={saved_reply.id}>
                        <div className="saved-reply">
                            <div className="saved-reply-item py-3"
                                onClick={()=>handleReplySelect(saved_reply.attributes.message)}>
                                {saved_reply.attributes.name}
                            </div>
                            <div className="saved-reply-actions">
                                <div className="px-2">
                                    <Button className="remove-page-link" clickHandler={()=>handleEdit(saved_reply)}>
                                        Edit
                                    </Button>
                                </div>
                                <div className="px-2">
                                    <Button className="remove-page-link" clickHandler={()=>handleDelete(saved_reply.id)}>
                                        Delete this reply?
                                    </Button>
                                </div>

                            </div>
                        </div>
                        <div className="line"></div>
                    </div>
                }) :
                    <div>
                        <button className="btn btn-primary my-4" onClick={()=>{setTabIndex(1)}}>Create reply</button>
                    </div>}
            </div>

            <button type="reset" className="btn btn-secondary float-end mx-2"
                    onClick={()=>{setShowSavedReplies(false)}}>Cancel</button>

        </>
    )
}

export default React.memo(SavedRepliesList)