import React from 'react';

import Brand from "./Brand"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRightFromBracket, faBars, faUser, faMoneyCheckDollar, faUsers, faGear, faListCheck, faFile, faUserGroup, faFileInvoice, faBullhorn} from '@fortawesome/free-solid-svg-icons'
import { faCircleQuestion} from '@fortawesome/free-regular-svg-icons'
import AiBook from "images/ai_book.svg"
import './Header.scss'
import {defineAbilities, errorNotify} from "../../../utils/app";
import NotificationBadge from "./NotificationBadge";
import Button from "../../UI/Button";
import axios from "axios";
import {CSRF} from "../../../constants";

const Header = (props) => {
    const ability = defineAbilities(props.rules)
    const fullname = props.firstname + " " + props.lastname

    const toggle_submenu = (e) => {
        e.stopPropagation();
        e.preventDefault();
    }

    const set_role = (role_id) => {
        axios.post(`/users/set_current_role`, {role_id: role_id}, {headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': CSRF}})
            .then(json => {
                document.location.reload()
            }).catch(error => {
            errorNotify('Error')
        })
        e.preventDefault()
    }

    const reset_role = (e) => {
        axios.post(`/users/reset_current_role`, {}, {headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': CSRF}})
            .then(json => {
                document.location.reload()
            }).catch(error => {
            errorNotify('Error')
        })
        e.preventDefault()
    }

    
    const resetFilter = (e) => {
        // dispatch(changeFilter())
    }

    return (
        <div className="header">
            <nav className="navbar navbar-toggleable-md navbar-light bg-faded navbar-expand flex-shrink-0 px-2">
                <div className="d-flex main-container align-items-center px-2 mx-auto">
                    <div className="navbar-header">
                        <Brand/>
                    </div>

                    <button aria-controls="navbarHeader" aria-expanded="false" aria-label="Toggle navigation"
                            className="navbar-toggler" data-target="#navbarHeader" data-toggle="collapse"
                            type="button">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="navbar-toggleable-sm collapse navbar-collapse justify-content-end text-center" id="navbarHeader">
                        <ul className="nav navbar-nav ml-auto">
                            {props.logged_in &&
                            <>
                                {(props.has_app_errors || props.trial || props.approached_comments_limit) && <NotificationBadge expire_in={props.expire_in} has_app_errors={props.has_app_errors}
                                                                                                        approached_comments_limit={props.approached_comments_limit}/>}
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="#" id="navbarScrollingDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {props.current_role_email &&
                                        <FontAwesomeIcon icon={faUsers} className="fa-fw me-2" data-tip={`${props.current_role_email} <span class="badge badge-light mx-2">${props.current_role_name}</span>`}/>}
                                        {fullname != " " ? fullname : "Menu"}
                                    </a>
                                    <ul className="dropdown-menu" aria-labelledby="navbarScrollingDropdown">
                                        <li>
                                            <a className="dropdown-item" href="/" data-turbolinks={false} >
                                                <FontAwesomeIcon icon={faBars} className="fa-fw"/>
                                                <span>Dashboard</span>
                                            </a>
                                        </li>

                                        <li>
                                            <a className="dropdown-item" href="/account-settings#profile">
                                                <FontAwesomeIcon icon={faUser} className="fa-fw"/>
                                                <span>Profile</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="/account-settings#notifications">
                                                <FontAwesomeIcon icon={faGear} className="fa-fw"/>
                                                <span>Settings</span>
                                            </a>
                                        </li>
                                        { ability.can('index', 'AccountLevel') && <li>
                                            <a className="dropdown-item" href="/account-settings#account">
                                                <FontAwesomeIcon icon={faFileInvoice} className="fa-fw"/>
                                                <span>Account</span>
                                            </a>
                                        </li>}
                                        { ability.can('access', 'Brand') && <li>
                                            <a className="dropdown-item" href="/account-settings#agents">

                                                <img src={AiBook} className="svg-inline--fa fa-fw book-ai" width="14" height="18"/>
                                                <span>Agents</span>
                                            </a>
                                        </li>}
                                        { ability.can('create', 'User') && <li>
                                            <a className="dropdown-item" href="/account-settings#users">
                                                <FontAwesomeIcon icon={faUserGroup} className="fa-fw"/>
                                                <span>Users</span>
                                            </a>
                                        </li>}
                                        {ability.can('destroy', 'PagesController') && props.token_present && <li>
                                            <a className="dropdown-item" href="account-settings#pages">
                                                <FontAwesomeIcon icon={faFile} className="fa-fw"/>
                                                <span>Pages</span>
                                            </a>
                                        </li>}
                                        {props.available_roles.length > 0 && <li>
                                            <div className="btn-group dropstart w-100">
                                            <a className="dropdown-item dropdown-toggle"
                                               href="#"
                                               data-bs-toggle="dropdown"
                                               data-bs-auto-close="outside"
                                               aria-expanded="false"
                                                onClick={toggle_submenu}>
                                                <FontAwesomeIcon icon={faUsers} className="fa-fw"/>
                                                <span>Roles</span>
                                            </a>

                                            <ul className="dropdown-menu dropdown-menu-submenu dropdown-menu-end dropdown-menu-lg-start">

                                                <li><Button className='dropdown-item' clickHandler={()=>reset_role()}>Reset</Button></li>
                                                {props.available_roles.filter(role => !role.attributes.current ).map((role, index) => {
                                                    return <div key={index}>
                                                        {index === 0 && <li><hr className="dropdown-divider"/></li>}
                                                            <li>
                                                                <Button className='dropdown-item' clickHandler={()=>set_role(role.id)}>
                                                                    <div className="submenu">{role.attributes.user_email}</div>
                                                                    <div className="submenu badge badge-light ms-2">{role.attributes.name}</div>
                                                                </Button>
                                                            </li></div>
                                                })}
                                            </ul>
                                            </div>
                                        </li>}
                                        <li>
                                            <a className="dropdown-item" href='https://headwayapp.co/commentguard-changelog' target="_blank">
                                                <FontAwesomeIcon icon={faBullhorn} className="fa-fw"/>
                                                <span>Updates</span>
                                            </a>
                                        </li>
                                        {/*{!props.trial && <li>*/}
                                        {/*    <a className="dropdown-item" href='https://commentguard.getrewardful.com/' target="_blank">*/}
                                        {/*        <FontAwesomeIcon icon={faMoneyCheckDollar} className="fa-fw"/>*/}
                                        {/*        <span>Affiliates</span>*/}
                                        {/*    </a>*/}
                                        {/*</li>}*/}
                                        <li>
                                            <a className="dropdown-item" href='https://commentguard.io/support/' target="_blank">
                                                <FontAwesomeIcon icon={faCircleQuestion} className="fa-fw"/>
                                                <span>Support</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href='/sign-out' data-method="DELETE">
                                                <FontAwesomeIcon icon={faRightFromBracket} className="fa-fw"/>
                                                <span>Sign out</span>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </>
                            }
                        </ul>
                    </div>
                </div>
            </nav>
            {(props.has_app_errors || props.trial || props.approached_comments_limit) && <NotificationBadge expire_in={props.expire_in} has_app_errors={props.has_app_errors}
                                                                                  approached_comments_limit={props.approached_comments_limit}/>}
        </div>
    )
}

export default React.memo(Header)