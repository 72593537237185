import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import SwitchItem from "../SwitchItem";
import {updatePage} from "../../../actions/requests";
import Button from "../Button";
import TagInput from "../TagInput";
import './Moderation.scss'
import axios from "axios";
import {CSRF} from "../../../constants";
import {AbilityContext, errorNotify, successNotify} from "../../../utils/app";
import TestTab from "../TestTab";
import {useAbility} from "@casl/react";
import EmojiContainer from "../EmojiContainer";
import {faFaceSmile} from "@fortawesome/free-regular-svg-icons";
import {setShowEmojiModal} from "../../../actions/ui";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import CopySettings from "./CopySettings";
import ModalWindow from "../ModalWindow";
import {useModerationForm} from './hooks/useModerationForm';
import {AdvancedSettingsSection} from './components/AdvancedSettingsSection';
import {DeleteModeModal} from './components/DeleteModeModal';
import {ImportSection} from './components/ImportSection';

const Moderation = (props) => {
  const dispatch = useDispatch()
  const moderationForm = useRef()
  const ability = useAbility(AbilityContext)
  const hostname = window.location.hostname
  const page = useSelector(state => state.pages.facebook.activePage)
  const user = useSelector(state => state.user)
  const { account_level } = user

  const {
    pageAttributes,
    userAttributes,
    emojiList,
    emojiSel,
    showDeleteModeModal,
    handleChange,
    handleEmojiSelect,
    clickOutsideEmoji,
    enableAdvancedOption,
    closeDeleteModeModal,
    deleteModeModal,
    deleteModeHandler,
    handleDeleteMode,
    setEmojiList,
    setEmojiSel,
    setShowDeleteModeModal
  } = useModerationForm(page, account_level.attributes, dispatch)

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  useEffect(() => {
    emojiSel && setEmojiList(emojiList.concat(emojiSel))
  }, [emojiSel])

  useEffect(() => {
    pageAttributes.hide_all_emojis && setEmojiList([])
  }, [pageAttributes.hide_all_emojis])

  const startImport = (e) => {
    e.preventDefault()

    axios({
      method: 'post',
      url: `/main/start_import`,
      data: {page_id: page.details.id},
      headers: { 'Content-Type': 'application/json',
        'X-CSRF-Token': CSRF}
    }).then(response => {
      // successNotify('Import has been successfully started')

    }).catch(errors => {
          errorNotify(errors.response.data.error)
        }
    );
  }

  return (
    <>
      <CopySettings setEmojiList={setEmojiList}/>

      <div className="settings-form my-4">
        <form onSubmit={(e) => e.preventDefault()} ref={moderationForm}>
          {/* Profanity and Sentiment Settings */}
          {userAttributes.hide_profanity && (
            <SwitchItem 
              title="Hide profanity"
              tooltip_place="bottom"
              value="true"
              checked={pageAttributes.hide_profanity}
              name="page[hide_profanity]"
              disabled={pageAttributes.private_mode}
              changeHandler={handleChange}
            />
          )}
          
          {userAttributes.hide_negative_sentiment && (
            <SwitchItem 
              title="Hide negativity"
              tooltip_place="bottom"
              value="true"
              checked={pageAttributes.hide_negative_sentiment}
              name="page[hide_negative_sentiment]"
              disabled={pageAttributes.private_mode}
              changeHandler={handleChange}
            />
          )}

          {/* URL Settings */}
          <SwitchItem 
            title="Hide URL's"
            value="true"
            checked={pageAttributes.hide_urls}
            name="page[hide_urls]"
            disabled={pageAttributes.private_mode}
            changeHandler={handleChange}
          >
            {pageAttributes.hide_urls && (
              <AdvancedSettingsSection
                isAdvanced={pageAttributes.hide_urls_advanced}
                onEnableAdvanced={(e) => enableAdvancedOption(e, "hide_urls")}
                tags={pageAttributes.hide_urls_list}
                inputId="hide_urls_list"
                placeholder="Type here (press enter after each url)"
                description="By default all URL's will be hidden, you can add domains or URL's you'd like to exclude from this filter."
                onSave={handleChange}
              />
            )}
          </SwitchItem>

          {/* Email and Phone Settings */}
          {userAttributes.detect_email_phone && (
            <SwitchItem 
              title="Hide emails and phone numbers"
              value="true"
              checked={pageAttributes.detect_email_phone}
              name="page[detect_email_phone]"
              disabled={pageAttributes.private_mode}
              changeHandler={handleChange}
            />
          )}

          {/* Mentions Settings */}
          <SwitchItem 
            title="Hide mentions"
            value="true"
            checked={pageAttributes.hide_mentions}
            name="page[hide_mentions]"
            disabled={pageAttributes.private_mode}
            changeHandler={handleChange}
          >
            {pageAttributes.hide_mentions && (
              <AdvancedSettingsSection
                isAdvanced={pageAttributes.hide_mentions_advanced}
                onEnableAdvanced={(e) => enableAdvancedOption(e, "hide_mentions")}
                tags={pageAttributes.hide_mentions_list}
                inputId="hide_mentions_list"
                placeholder="Type here (press enter after each name of account handle)"
                description="Here you can add mentions you'd like to exclude from this filter."
                onSave={handleChange}
              />
            )}
          </SwitchItem>

          {/* Hashtags Settings */}
          <SwitchItem 
            title="Hide hashtags"
            value="true"
            checked={pageAttributes.hide_hashtags}
            name="page[hide_hashtags]"
            disabled={pageAttributes.private_mode}
            changeHandler={handleChange}
          >
            {pageAttributes.hide_hashtags && (
              <AdvancedSettingsSection
                isAdvanced={pageAttributes.hide_hashtags_advanced}
                onEnableAdvanced={(e) => enableAdvancedOption(e, "hide_hashtags")}
                tags={pageAttributes.hide_hashtags_list}
                inputId="hide_hashtags_list"
                placeholder="Type here (press enter after each hashtag)"
                description="Here you can add hashtags you'd like to exclude from this filter."
                onSave={handleChange}
              />
            )}
          </SwitchItem>

          {/* Images Settings */}
          {userAttributes.hide_images && (
            <SwitchItem 
              title="Hide images"
              value="true"
              checked={pageAttributes.hide_images}
              name="page[hide_images]"
              disabled={pageAttributes.private_mode}
              changeHandler={handleChange}
            />
          )}

          {/* Emojis Settings */}
          {userAttributes.hide_emojis && (
            <SwitchItem 
              title="Hide emojis"
              value="true"
              checked={pageAttributes.hide_emojis}
              name="page[hide_emojis]"
              disabled={pageAttributes.private_mode}
              changeHandler={handleChange}
            >
              {pageAttributes.hide_emojis && (
                <>
                  <div className="ml-4 mt-3 collapse show" />
                  <div className="row languages-select">
                    <div className="col-lg-3">
                      <select 
                        className="form-select" 
                        value={pageAttributes.hide_all_emojis.toString()} 
                        id="page[hide_all_emojis]" 
                        onChange={handleChange}
                      >
                        <option value="true">Hide all emojis</option>
                        <option value="false">Hide specific emojis</option>
                      </select>
                    </div>
                  </div>
                  {!pageAttributes.hide_all_emojis && (
                    <div className="row languages-select mt-3">
                      <div className="col-12">
                        <div className="emoji-icon-wrap">
                          <FontAwesomeIcon 
                            icon={faFaceSmile}
                            className="mx-2 emoji-icon"
                            size="lg"
                            onClick={() => dispatch(setShowEmojiModal(true, "emoji_list"))}
                          />
                        </div>
                        <TagInput 
                          name="page[emoji_list]" 
                          tags={emojiList} 
                          inputId="emoji_list" 
                          placeholder="Enter your emojis here"
                        />
                        <div className="d-flex justify-content-end mt-3 mb-4">
                          <Button 
                            name="button" 
                            className="btn btn-primary save-blacklist" 
                            clickHandler={handleChange}
                          >
                            Save
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </SwitchItem>
          )}

          {/* Keywords Settings */}
          {userAttributes.blacklist && (
            <SwitchItem 
              title="Hide keywords"
              value="true"
              checked={pageAttributes.blacklist}
              name="page[blacklist]"
              disabled={pageAttributes.private_mode}
              changeHandler={handleChange}
            >
              {pageAttributes.blacklist && (
                <>
                  <div className="ml-4 mt-3 collapse show" />
                  <div className="row languages-select">
                    <div className="col-12">
                      <TagInput 
                        name="page[tags]" 
                        tags={pageAttributes.tags} 
                        copy={true}
                      />
                      <div className="d-flex justify-content-end mt-3 mb-4">
                        <Button 
                          name="button" 
                          className="btn btn-primary save-blacklist" 
                          clickHandler={handleChange}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </SwitchItem>
          )}

          {/* Private Mode Settings */}
          {userAttributes.private_mode && (
            <>
              <div className="line mt-4" />
              <SwitchItem 
                title="Hide all comments"
                value="true"
                checked={pageAttributes.private_mode}
                name="page[private_mode]"
                changeHandler={handleChange}
              >
                {pageAttributes.private_mode && (
                  <AdvancedSettingsSection
                    isAdvanced={pageAttributes.private_mode_advanced}
                    onEnableAdvanced={(e) => enableAdvancedOption(e, "private_mode")}
                    tags={pageAttributes.whitelist}
                    inputId="page[whitelist]"
                    placeholder="Type here (press enter after each word)"
                    description="By default all comments will be hidden, you can add words you'd like to exclude from this filter."
                    onSave={handleChange}
                    copy={true}
                  />
                )}
              </SwitchItem>
            </>
          )}

          <div className="line mt-4" />

          {/* Delete Mode Settings */}
          <SwitchItem 
            title="Delete comments"
            desc="Automatically delete (instead of hide) comments that trigger your moderation settings."
            value="true"
            checked={pageAttributes.delete_mode}
            name="page[delete_mode]"
            wrapperClass="settings-item settings-item-alert"
            changeHandler={deleteModeHandler}
          />

          {/* Delete Mode Modal */}
          {showDeleteModeModal && (
            <DeleteModeModal
              onClose={closeDeleteModeModal}
              onSubmit={handleDeleteMode}
            />
          )}

          {/* Organic Moderation Settings */}
          {userAttributes.disable_organic && (
            <>
              <div className="line mt-4" />
              <SwitchItem 
                title="Disable organic moderation"
                desc="By default comments on both ads and organic content are moderated."
                value="true"
                checked={pageAttributes.disable_organic}
                name="page[disable_organic]"
                changeHandler={handleChange}
              />
            </>
          )}

          <div className="line mt-4" />
        </form>

        {/* Test Tab */}
        {ability.can("test_comment","PagesController") && (
          <>
            <div className="my-4">
              Here you can test comments to see if they would trigger your enabled moderation settings.
            </div>
            <TestTab />
          </>
        )}

        {/* Import Section */}
        {props.admin && (hostname === "dev.commentguard.io" || hostname === "localhost") && (
          <ImportSection pageId={page.details.id} />
        )}

        <EmojiContainer 
          handleEmojiSelect={handleEmojiSelect} 
          clickOutside={clickOutsideEmoji}
        />
      </div>
    </>
  )
}

export default React.memo(Moderation)