import React, {useEffect, useState, useMemo, useRef} from 'react'
import { Flex, Title, Icon, TabGroup, TabList, Tab, AreaChart, Text, Color } from "@tremor/react";

import './Analytic.scss'

import {getAnalytics, requestRules} from "../../../actions/requests";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {CSRF, getIcon} from "../../../constants";
import { ResponsiveLine } from '@nivo/line'
import { ResponsiveBar } from '@nivo/bar'
import { TooltipWrapper } from '@nivo/tooltip';
import DateRangePicker from "react-date-range/dist/components/DateRangePicker";
import {defaultStaticRanges} from "react-date-range/src/defaultRanges";
import 'react-calendar/dist/Calendar.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar, faXmark} from "@awesome.me/kit-a13c95776b/icons/modules/sharp/thin";
import {TabPanel} from "react-tabs";
import { timeFormat } from 'd3-time-format'
import { scaleTime } from 'd3-scale'
import {addDays, startOfWeek, endOfWeek} from "date-fns";

const Analytics = (props) => {

    const dispatch = useDispatch()
    const [selectedIndex, setSelectedIndex] = useState(0)
    const {id: pageId} = useSelector(state => state.pages.facebook.activePage.details)
    const [data, setData] = useState([])
    const keys = ["Total", "Visible", "Hidden", "Deleted"];
    const legend_colors = ['#eef1ee', '#0c9f647a', '#2bae76', '#f47560']
    const colors = ['#0c9f647a', '#2bae76', '#f47560']
    const [totalData, setTotalData] = useState()

    const [startDate, setStartDate] = useState(startOfWeek(new Date()))
    const [endDate, setEndDate] = useState(endOfWeek(new Date()))
    const [staticRange, setStaticRange] = useState(false)
    const dropdownRef = useRef()

    const handleStaticRange = () => {
        setStaticRange(true)
    }

    const callAnalytics = (dates) => {
        axios.post(`/analytics`, {page_id: pageId, dates: dates}, {headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': CSRF}})
            .then(json => {
                if(json.data[0].data?.length > 0) {
                    setTotalData([{name: "Total comments", value: json.data[0].total_comments},
                        {name: "Total visible", value: json.data[0].total_visible},
                        {name: "Total hidden", value: json.data[0].total_hidden},
                        {name: "Total deleted", value: json.data[0].total_deleted}])
                }else{
                    setTotalData([])
                }
                setData(json.data)
            }).catch(error => {
        })
    }

    useEffect(()=>{
        callAnalytics([formatter_submit(startDate), formatter_submit(endDate)])
    },[pageId])

    const formatter = timeFormat('%m-%d-%Y')
    const formatter_submit = timeFormat('%d-%m-%Y')
    const formatter_alt = timeFormat('%b %d, %Y')
    const timeScaleTicks = useMemo(() => {
        if(endDate) {
            const scaleS = scaleTime().domain([startDate, endDate])
            const ticks = scaleS.ticks(10)
            return ticks.map((tick) => {
                return formatter(tick)
            })
        }
    },[endDate])

    const tickFormat = (d) => {
        var date = new Date(d);
        if (date.getHours() == 0){
            return timeFormat('%-M min')(d)
        } else {
            if (date.getMinutes() == 0){
                return timeFormat('%-S sec')(d)
            }else{
                return timeFormat('%-H h %-M min')(d)
            }
        }

    }

    const isMobile = /Mobi|Android/i.test(navigator.userAgent)

    useEffect(()=>{
        const divs = document.querySelectorAll('.rdrStaticRange');
        divs.forEach(el => el.addEventListener('click', handleStaticRange));
        return () => {
            divs.forEach(el => el.removeEventListener('click', handleStaticRange));
        }
    },[])

    const handleSelect = (date) => {
        setRange([date.selection])
        if(date.selection.startDate.getTime()!==date.selection.endDate.setHours(0, 0, 0, 0)) {
            setEndDate(date.selection.endDate)
            setStartDate(date.selection.startDate)
            callAnalytics([formatter_submit(date.selection.startDate), formatter_submit(date.selection.endDate)])
            dropdownRef.current.classList.remove('show')
        }
    }

    const [range, setRange] = useState([
        {
            startDate: startDate || new Date(),
            endDate: endDate || startDate || new Date(),
            key: 'selection'
        }
    ]);

    return (
        <div className="analytics">
            <div className="my-4">
                Here you can get statistics and insights about the comments you have received.
            </div>
            <div className="line"/>
            <div className="row">
                <div className="col-sm-6">
                    {data[0]?.data?.length <= 0 &&
                        <div className="mt-4">
                            Nothing to see here…
                        </div>
                    }
                </div>
                <div className="col-sm-6 my-4">
                    <div className="accounts-list dates-list position-relative d-flex">
                        <button type="button" className="form-select dropdown-toggle dropdown-toggle-n actions-btn dates-btn" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                            <span className="ms-2">{formatter_alt(startDate)} - {formatter_alt(endDate)}</span>
                        </button>
                            <div className="dropdown-menu w-100 dropdown-menu-end dates-selector" ref={dropdownRef}>
                                <DateRangePicker
                                    onChange={handleSelect}
                                    showDateDisplay={false}
                                    editableDateInputs={false}
                                    rangeColors={['#2bae76']}
                                    showMonthAndYearPickers={false}
                                    inputRanges={[]}
                                    ranges={range}
                                    staticRanges={defaultStaticRanges.slice(2)}
                                />
                            </div>
                    </div>
                </div>
            </div>


            <div className="row my-2">
                {totalData && totalData.map((d, index) => {
                    return <div key={index} className="col-xs-6 col-sm-3">
                                <div className="card">
                                    <div className="card-body">
                                        <p className="card-text">{d.name}</p>
                                        <h3 className="card-title">{d.value}</h3>
                                    </div>
                                </div>
                            </div>

                })}
            </div>

            {data[0]?.data?.length > 0 &&
                <>
                    <div className="mt-8 area">

                        <ResponsiveBar
                            data={data[0].data}
                            keys={[
                                'Visible',
                                'Hidden',
                                'Deleted'
                            ]}
                            indexBy="date"
                            margin={{ top: 60, right: 20, bottom: 70, left: 40 }}
                            padding={0.2}
                            valueScale={{ type: 'linear' }}
                            indexScale={{ type: 'band', round: true }}
                            colors={colors}
                            borderColor={{
                                from: 'color',
                                modifiers: [
                                    [
                                        'darker',
                                        1.6
                                    ]
                                ]
                            }}
                            axisTop={null}
                            axisRight={null}
                            axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: isMobile ? -90 : 0,
                                legendPosition: 'middle',
                                legendOffset: 32,
                                truncateTickAt: 15,
                                format: (val) => {
                                    if(data[0]?.data?.length > 10){
                                        return timeScaleTicks.includes(formatter(new Date(val))) ? formatter(new Date(val)) : ''
                                    }else{
                                        return formatter(new Date(val))
                                    }
                                }
                            }}

                            axisLeft={{
                                format: (e) => (Math.floor(e) === e ? e : ""),
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legendPosition: 'middle',
                                legendOffset: -40,
                                truncateTickAt: 0
                            }}
                            labelSkipWidth={12}
                            labelSkipHeight={12}
                            labelTextColor={{
                                from: 'color',
                                modifiers: [
                                    [
                                        'darker',
                                        1.6
                                    ]
                                ]
                            }}
                            legends={[
                                {
                                    dataFrom: 'keys',
                                    // data: keys.map((id, index) => ({
                                    //         color: legend_colors[index],
                                    //         id,
                                    //         label: data[0] && `${id}: ${data[0][id]}`
                                    //     })),
                                    anchor: 'top-left',
                                    direction: 'row',
                                    justify: false,
                                    translateX: 0,
                                    translateY: -55,
                                    itemsSpacing: 2,
                                    itemWidth: 100,
                                    itemHeight: 20,
                                    itemDirection: 'left-to-right',
                                    itemOpacity: 0.85,
                                    symbolSize: 20,
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemOpacity: 1
                                            }
                                        }
                                    ]
                                }
                            ]}
                            tooltip={({
                                          id,
                                          indexValue,
                                          value,
                                          color,
                                          label
                                      }) => <><div style={{background: 'white', color: 'inherit', fontSize: 13, borderRadius: 2, boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 2px', padding: '5px 9px'}}>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <span style={{display: "block", width: "12px", height: "12px", background: color, marginRight: "7px"}}></span>
                                    <span>{id} - {formatter_alt(new Date(indexValue))}: <strong>{value}</strong></span>
                                </div>
                                </div></>
                        }

                        />

                    </div>

                <div className="replies-section">

                    <div className="fw-bold my-4">Replies</div>
                    <div className="line my-4"/>

                    <div className="row mb-4">
                        <div className="col-xs-6 col-sm-3">
                            <div className="card">
                                <div className="card-body">
                                    <p className="card-text">Total replies sent</p>
                                    <h3 className="card-title">{data[0].replies?.total || 0}</h3>
                                </div>
                            </div>
                        </div>
                    </div>

                    {data[0].replies?.total > 0 &&<div className="replies-stat">
                        <div className="row my-2">
                            <div className="col-6 col-sm-4">
                                Replied-by:
                            </div>
                            <div className="col-3 col-sm-2 num">
                                Replies
                            </div>
                            <div className="col-3 col-sm-2 avg">
                                Avg. response time
                            </div>
                            <div className="line my-2"/>
                        </div>


                        {data[0].replies?.data && data[0].replies.data.map((r, index) => {
                            return <div key={index} className="row my-2">
                                <div className="accordion accordion-flush" id={`flush_${index}`}>
                                    <div className="accordion-header row" id={`heading_${index}`}>
                                        <div className="col-6 col-sm-4">
                                        <button className="accordion-button a-accordion-button collapsed p-0" type="button"
                                                data-bs-toggle="collapse" data-bs-target={`#flush-collapse_${index}`}
                                                aria-expanded="false" aria-controls={`flush-collapse_${index}`}>
                                                {r._id.replace("Rule", "Automation").replace("Brand", "Agent")}
                                        </button>
                                        </div>
                                        <div className="col-3 col-sm-2 num">
                                            {r.count}
                                        </div>
                                        <div className="col-3 col-sm-2 avg">
                                            {tickFormat(r.avg)}
                                        </div>
                                        <div id={`flush-collapse_${index}`} className="accordion-collapse collapse"
                                             aria-labelledby={`heading_${index}`} data-bs-parent={`#flush_${index}`}>
                                            <div className="accordion-body px-0">
                                                {r.names.map((name, i) => {
                                                    return <div key={i} className="row py-2">
                                                        <div className="col-6 col-sm-4">
                                                            {name.name.replace(/Replied by: |Auto-reply: |Agent-reply: /g, "")}
                                                        </div>
                                                        <div className="col-3 col-sm-2 num">
                                                            {name.count}
                                                        </div>
                                                        <div className="col-3 col-sm-2 avg">
                                                            {tickFormat(name.avg)}
                                                        </div>
                                                    </div>
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="line my-2"/>
                            </div>

                        })}

                    </div>}

                </div>
                </>
            }
        </div>
    )
}

export default React.memo(Analytics)