import React from 'react'
import TagInput from "../../TagInput"
import Button from "../../Button"

export const AdvancedSettingsSection = ({
  isAdvanced,
  onEnableAdvanced,
  tags,
  inputId,
  placeholder,
  description,
  onSave,
  copy = false
}) => {
  if (isAdvanced) {
    return (
      <>
        <div className="ml-4 mt-3 collapse show" />
        <div className="row languages-select">
          <div className="col-12">
            <TagInput
              name={`page[${inputId}]`}
              tags={tags}
              inputId={inputId}
              placeholder={placeholder}
              copy={copy}
            />
            {description && (
              <div className="sent-text-extra">
                {description}
              </div>
            )}
            <div className="d-flex justify-content-end mb-4">
              <Button
                name="button"
                className="btn btn-primary save-blacklist"
                clickHandler={onSave}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <div className="row languages-select my-2">
      <a
        href="#"
        className="fs-08"
        onClick={onEnableAdvanced}
      >
        Advanced settings (by default we hide all {inputId.split('_').pop()})
      </a>
    </div>
  )
} 