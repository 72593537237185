import React from 'react'
import {useSelector} from "react-redux";
import PagesSelectionItem from "../PagesSelectionItem";
import "./PagesSelection.scss"
import Button from "../Button";
import {getIcon} from "../../../constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useAbility} from "@casl/react";
import {AbilityContext} from "../../../utils/app";

const PagesSelection = (props) => {
    const pages = useSelector(state => state.pages.facebook.selectionList.list)
    const ability = useAbility(AbilityContext)
    const addDisallowed = ability.can("create", "PagesController")

    return (
        <div className="accounts-list position-relative">
            <button type="button" className="form-select dropdown-toggle dropdown-toggle-n actions-btn" data-bs-toggle="dropdown"
                    aria-expanded="false">
                {pages && pages.length < 1 && ability.cannot("create", "PagesController")
                    ? <span className="ms-2">No pages</span>
                    : <><FontAwesomeIcon icon={getIcon(props.pageType)} beatFade={props.load} className="fa-fw" />
                        <span className="ms-2">{props.pageName || "Add new page"}</span></>}
            </button>
            {pages && pages.length <= 1 && ability.cannot("create", "PagesController") ? <></> : <ul className="dropdown-menu w-100 pages-selector">
                {pages && (pages.map((page, index) => {
                    return <div key={page.id}>
                        <PagesSelectionItem
                                   pageId={page.id}
                                   handleInputChange={props.handleInputChange}
                                   pageType={page.type}
                                   pageName={page.displayName}/>
                    </div>
                }))}
                {addDisallowed && <PagesSelectionItem pageId="New"
                                    handleInputChange={props.handleInputChange}
                                    pageName="Add new page"/>}
            </ul>}

        </div>
    )
}

export default React.memo(PagesSelection)