import React, {useEffect, useRef, useState} from 'react';
import {requestUserDetails} from "../../../actions/requests";

import {batch, useDispatch, useSelector} from "react-redux";

import {initNotificationChannel} from "../../../channels/notifications_channel";
import "./AccountSettings.scss"
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import { useLocation, Navigate } from 'react-router-dom'
import AccountSettingsProfile from "./Profile";
import AccountSettingsPages from "./Pages";
import AccountSettingsAccount from "./Account";
import AccountSettingsUsers from "./Users";
import {useAbility} from "@casl/react";
import {AbilityContext} from "../../../utils/app";
import Notifications from "../../UI/Notifications";
import Brands from "./Brands";

const AccountSettings = (props) => {
    const dispatch = useDispatch()
    const ability = useAbility(AbilityContext);

    const user = useSelector(state => state.user)
    const {pages: userPages, account_level, profile} = user

    const [tabIndex, setTabIndex] = useState(0);
    const {hash: locationHash} = useLocation()

    useEffect(() => {
        batch(() => {
            dispatch(requestUserDetails())
            dispatch(initNotificationChannel())
        })
    }, [])

    useEffect(()=>{
        let tabs = ['profile','notifications']
        ability.can('index', 'AccountLevel') && tabs.push("account")
        ability.can('access', 'Brand') && tabs.push("agents")
        ability.can('create', 'User') && tabs.push("users")
        ability.can('destroy', 'PagesController') && tabs.push("pages")
        const tab_id = tabs.indexOf(locationHash.replace('#',''))
        if(tab_id !==-1){
            setTabIndex(tab_id)
        }else{
            setTabIndex(0)
        }
    },[locationHash])

    return (
        <>
            <div className="mb-4 mt-2">
                <h4>
                    Account settings
                </h4>
            </div>
            <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)} className="rect-tabs pt-2">
                <TabList>
                    <Tab>Profile</Tab>
                    <Tab>Settings</Tab>
                    { ability.can('index', 'AccountLevel') && <Tab>Account</Tab>}
                    { ability.can('access', 'Brand') && <Tab>Agents</Tab>}
                    { ability.can('create', 'User') && <Tab>Users</Tab>}
                    { ability.can('destroy', 'PagesController') && <Tab>{props.token_present && "Pages"}</Tab>}
                </TabList>

                <TabPanel>
                    <AccountSettingsProfile profile={profile}/>
                </TabPanel>

                <TabPanel>
                    <Notifications />
                </TabPanel>

                { ability.can('index', 'AccountLevel') && <TabPanel>
                    <AccountSettingsAccount profile={profile} admin={props.admin} account_level={account_level}/>
                </TabPanel>}

                { ability.can('access', 'Brand') && <TabPanel>
                    <Brands/>
                </TabPanel>}

                { ability.can('create', 'User') && <TabPanel>
                    <AccountSettingsUsers/>
                </TabPanel>}

                <TabPanel>
                    {ability.can('destroy', 'PagesController') && (props.token_present ?
                    <AccountSettingsPages profile={profile}
                                      account_level={account_level}
                                      userPages={userPages}/>
                        : <Navigate replace to="/" />)}
                </TabPanel>

            </Tabs>
        </>
    )
}

export default React.memo(AccountSettings)