import { useState, useEffect } from 'react'
import { updatePage } from '../../../../actions/requests'
import { setShowEmojiModal } from '../../../../actions/ui'

export const useModerationForm = (page, userAttributes, dispatch) => {
  const [emojiList, setEmojiList] = useState(page.details.attributes.emoji_list)
  const [emojiSel, setEmojiSel] = useState()
  const [showDeleteModeModal, setShowDeleteModeModal] = useState(false)

  const pageAttributes = page.details.attributes

  const handleChange = (e) => {
    const formData = new FormData(e.target.form)
    const checkboxes = document.querySelectorAll("input[type=checkbox]")
    Array.from(checkboxes).forEach(checkbox => {
      formData.append(checkbox.name, checkbox.checked)
    })

    // Handle blacklist tags
    if (pageAttributes.blacklist) {
      const tagList = document.getElementById("tag-list")
      formData.append("page[tags]", tagList.value)
    }

    // Handle emoji settings
    const hideAllEmojisEl = document.getElementById("page[hide_all_emojis]")
    const emojiListEl = document.getElementById("emoji_list")
    if (pageAttributes.hide_emojis) {
      formData.append("page[hide_all_emojis]", hideAllEmojisEl.value)
      formData.append("page[emoji_list]", emojiListEl ? emojiListEl.value : "")
    } else {
      formData.append("page[hide_all_emojis]", true)
      formData.append("page[emoji_list]", "")
    }

    // Handle URL settings
    if (e.target.id === "page[hide_urls]" && !e.target.checked) {
      const hideUrlAdv = document.getElementById("hide_urls_advanced")
      const whitelistEl = document.getElementById("page[whitelist]")
      hideUrlAdv && (hideUrlAdv.checked = false)
      formData.append("page[hide_urls_list]", "")
      formData.append("page[hide_urls_advanced]", false)
      whitelistEl && !whitelistEl.value && formData.append("page[whitelist]", "")
    }

    // Handle private mode settings
    if (e.target.id === "page[private_mode]" && !e.target.checked) {
      const privateModeAdv = document.getElementById("private_mode_advanced")
      privateModeAdv && (privateModeAdv.checked = false)
      formData.append("page[private_mode_advanced]", false)
    }

    // Handle whitelist
    if (pageAttributes.private_mode) {
      const whitelistEl = document.getElementById("page[whitelist]")
      whitelistEl && formData.append("page[whitelist]", whitelistEl.value)
    }

    // Handle URL list
    if (pageAttributes.hide_urls) {
      const hideUrlList = document.getElementById("hide_urls_list")
      hideUrlList && formData.append("page[hide_urls_list]", hideUrlList.value)
    }

    // Handle mentions settings
    if (e.target.id === "page[hide_mentions]" && !e.target.checked) {
      const hideMentionsAdv = document.getElementById("hide_mentions_advanced")
      hideMentionsAdv && (hideMentionsAdv.checked = false)
      formData.append("page[hide_mentions_list]", "")
      formData.append("page[hide_mentions_advanced]", false)
    }

    if (pageAttributes.hide_mentions) {
      const hideMentionsList = document.getElementById("hide_mentions_list")
      hideMentionsList && formData.append("page[hide_mentions_list]", hideMentionsList.value)
    }

    // Handle hashtags settings
    if (e.target.id === "page[hide_hashtags]" && !e.target.checked) {
      const hideHashtagsAdv = document.getElementById("hide_hashtags_advanced")
      hideHashtagsAdv && (hideHashtagsAdv.checked = false)
      formData.append("page[hide_hashtags_list]", "")
      formData.append("page[hide_hashtags_advanced]", false)
    }

    if (pageAttributes.hide_hashtags) {
      const hideHashtagsList = document.getElementById("hide_hashtags_list")
      hideHashtagsList && formData.append("page[hide_hashtags_list]", hideHashtagsList.value)
    }

    setShowDeleteModeModal(false)
    dispatch(updatePage(page.details.id, formData))
  }

  const handleEmojiSelect = (emoji) => {
    setEmojiSel(emoji.native)
  }

  const clickOutsideEmoji = (e) => {
    if (!e.target.classList.contains("emoji-icon") && 
        e.target.localName !== "path" && 
        e.target.localName !== "em-emoji-picker") {
      dispatch(setShowEmojiModal(false))
    }
  }

  const enableAdvancedOption = (e, option_name) => {
    e.preventDefault()
    const formData = new FormData(e.target.form)
    formData.append(`page[${option_name}_advanced]`, true)
    dispatch(updatePage(page.details.id, formData))
  }

  const closeDeleteModeModal = () => {
    setShowDeleteModeModal(false)
  }

  const deleteModeModal = () => {
    setShowDeleteModeModal(true)
  }

  const deleteModeHandler = (e) => {
    if (pageAttributes.delete_mode) {
      handleChange(e)
    } else {
      setShowDeleteModeModal(true)
    }
  }

  const handleDeleteMode = (e) => {
    e.preventDefault()
    const formData = new FormData(e.target.form)
    formData.append(`page[delete_mode]`, true)
    dispatch(updatePage(page.details.id, formData))
    setShowDeleteModeModal(false)
  }

  return {
    pageAttributes,
    userAttributes,
    emojiList,
    emojiSel,
    showDeleteModeModal,
    handleChange,
    handleEmojiSelect,
    clickOutsideEmoji,
    enableAdvancedOption,
    closeDeleteModeModal,
    deleteModeModal,
    deleteModeHandler,
    handleDeleteMode,
    setEmojiList,
    setEmojiSel,
    setShowDeleteModeModal
  }
} 