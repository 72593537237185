import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import "./Brands.scss"
import Button from "../../../UI/Button";
import BrandsModal from "./BrandsModal";
import {removeBrand, setSelectedBrand} from "../../../../actions/brands";
import SwitchItem from "../../../UI/SwitchItem";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getIcon} from "../../../../constants";
import {setShowBrandsModal} from "../../../../actions/ui";
import {updateBrands} from "../../../../actions/requests";

const BrandsItem = ({brandId, setShowRemoveBrandsModal}) => {
    const dispatch = useDispatch()
    const brand = useSelector(state => state.brands.brandsList[brandId])
    const {name, status, enabled, pages} = brand.attributes

    const editBrandModal = (brand) => {
        dispatch(setSelectedBrand(brand))
        dispatch(setShowBrandsModal())
    }

    const removeBrandModal = (brand) => {
        dispatch(setSelectedBrand(brand))
        setShowRemoveBrandsModal(true)
    }

    const enableBrand = (e, id) => {
        e.preventDefault()
        const formData = new FormData()
        formData.append("brand[enabled]", e.target.checked)
        dispatch(updateBrands(id, formData))
    }

    return (
        <div className={`d-flex py-3 brand-line ${!enabled && "disabled"} `} key={brand.id}>
                <SwitchItem  title={name}
                             wrapperClass="d-flex brand-name"
                             checked={enabled}
                             name="brand[enabled]"
                             changeHandler={(e) => enableBrand(e, brand.id)}>
                    <div className="badge badge-light mx-2">
                        {status}
                    </div>
                </SwitchItem>

                <div className="d-flex brand-pages">
                    {pages.slice(0,2).map((page) => {
                        return <div className="badge badge-light me-1" key={page.id}>
                            <FontAwesomeIcon icon={getIcon(page.type)} className="fa-fw"/>
                            {page.attributes.displayName}
                        </div>
                    })}
                    {pages.length > 2 && <>...</>}
                </div>
                <div className="d-flex px-0 brand-actions">
                    <div className="px-2">
                        <Button className="remove-page-link" clickHandler={()=>editBrandModal(brand)}>
                            Edit
                        </Button>
                    </div>
                    <div className="px-2">
                        <Button className="remove-page-link" clickHandler={()=>removeBrandModal(brand)}>
                            Remove this agent?
                        </Button>
                    </div>
                </div>
        </div>
    )
}

export default React.memo(BrandsItem)