import React, {useState} from 'react';
import axios from "axios";
import {CSRF} from "../../../constants";
import {errorNotify} from "../../../utils/app";
import Button from "../Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSparkles} from "@awesome.me/kit-a13c95776b/icons/modules/sharp/solid";

const AiAction = ({ msgRef, instructRef, aiFeedbackRef, feedbackRef, setMessage, pageId, objectId, toneRef, dm=false}) => {
    const [load, setLoad] = useState(false)

    const ai_reply = (e) => {
        e.preventDefault()
        setLoad(true)
        const instructionsValue = instructRef?.current?.value
        const toneOfVoiceValue = toneRef?.current?.value
        const feedbackValue = feedbackRef?.current?.value

        const formData = new FormData()
        formData.append("page_id", pageId)
        instructionsValue &&  formData.append("instructions", instructionsValue)
        toneOfVoiceValue &&  formData.append("tone_of_voice", toneOfVoiceValue)
        feedbackValue &&  formData.append("feedback_instructions", feedbackValue)

        axios({
            method: 'post',
            url: dm ? `/pages/${pageId}/direct_messages/${objectId}/ai_reply` : `/comments/${objectId}/ai_reply`,
            data: formData,
            headers: { 'Content-Type': 'application/json',
                'X-CSRF-Token': CSRF}
        }).then(response => {
            setLoad(false)
            console.log('response.data', response.data)
            if(response.data.success){
                setMessage(response.data.text, feedbackValue ? true : false)
                if(!feedbackValue){
                    aiFeedbackRef.current.style.display = "block"
                }
            }else{
                errorNotify("Service unavailable now.")
            }
        }).catch(error => {
                setLoad(false)
                errorNotify("Service unavailable now.")
            }
        );
    }

    return (

        <Button className="btn ai-btn" clickHandler={ai_reply}>
            Generate
            <FontAwesomeIcon icon={faSparkles} beatFade={load} className="ms-2"/>
        </Button>
    )
}

export default AiAction;
