import {BRANDS_ACTIONS} from "../constants";

const initState = {
    selectedBrand: {},
    brandsList: [],
    availablePages: [],
    selectedPages: []
}


export function brandsReducer(state=initState, action) {
    const {payload} = action

    switch (action.type) {
        case BRANDS_ACTIONS.REQUEST_BRANDS:
            return {
                ...state,
                brandsList: payload.data
            }
    case BRANDS_ACTIONS.ADD_BRANDS:
            return {
                ...state,
                brandsList: [payload.data, ...state.brandsList]
            }
    case BRANDS_ACTIONS.UPDATE_BRANDS:
        return {
            ...state,
            brandsList: state.brandsList.map(brand => {
                if (brand.id === payload.brandId) {
                    return {
                        ...brand,
                        attributes: Object.assign(brand.attributes, payload.attributes)
                    }
                } else {
                    return {
                        ...brand
                    }
                }
            })
        }
        case BRANDS_ACTIONS.REMOVE_BRANDS:
            return {
                ...state,
                brandsList: state.brandsList.filter(brand => brand.id !== payload.brandId)
            }
        case BRANDS_ACTIONS.REMOVE_FEEDBACK:
            console.log('payload.feedbackId', payload.feedbackId)
            return {
                ...state,
                selectedBrand: {
                    ...state.selectedBrand,
                    attributes: {
                        ...state.selectedBrand.attributes,
                        reply_feedbacks: state.selectedBrand.attributes.reply_feedbacks.filter(feedback => feedback.id !== payload.feedbackId)
                    }
                }
                //     state.selectedBrand.map(brand => {
                //     if (brand.id === payload.brandId) {
                //         return {
                //             ...brand,
                //             attributes: {
                //                 ...brand.attributes,
                //                 reply_feedbacks: brand.attributes.reply_feedbacks.filter(feedback => feedback.id !== payload.feedbackId)
                //             }
                //         }
                //     } else {
                //         return {
                //             ...brand
                //         }
                //     }
                // })
            }
        case BRANDS_ACTIONS.INIT_PAGES:
            return {
                ...state,
                availablePages: payload.data
            }
        case BRANDS_ACTIONS.ADD_PAGES:
            return {
                ...state,
                availablePages: [payload.data, ...state.availablePages]
            }
        case BRANDS_ACTIONS.REMOVE_PAGES:
            return {
                ...state,
                availablePages: state.availablePages.filter(page => page.id !== payload.pageId)
            }
        case BRANDS_ACTIONS.INIT_CURRENT_PAGES:
            return {
                ...state,
                selectedPages: payload.data
            }
        case BRANDS_ACTIONS.ADD_CURRENT_PAGES:
            return {
                ...state,
                selectedPages: [payload.data, ...state.selectedPages]
            }
        case BRANDS_ACTIONS.REMOVE_CURRENT_PAGES:
            return {
                ...state,
                selectedPages: state.selectedPages.filter(page => page.id !== payload.pageId)
            }
        case BRANDS_ACTIONS.SET_SELECTED_BRAND:
            return {
                ...state,
                selectedBrand: payload.selectedBrand
            }
        default:
            return state
    }
}