import React from 'react'

import {faComments, faCheck} from '@fortawesome/free-solid-svg-icons'
import {faFacebook} from "@fortawesome/free-brands-svg-icons";
import './PlanItem.scss'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CSRF, numberWithCommas} from "../../../constants";

const PlanItem = ({selected, level, show_plans, cur_trial}) => {
    const {name, price, comments_limit, fb_pages_limit, visible, sub_users_limit, data_retention, trial, ai_replies_limit} = level.attributes
    const page_text = fb_pages_limit==1 ? "page" : "pages"
    const sub_users_text = sub_users_limit==1 ? "user" : "users"
    return (
        <>
            {visible && (
                <div className="col-sm-3 my-2">
                    <div className="card h-100">
                        <div className="card-body">

                            <h5 className="card-title">{name}</h5>
                            <div className="my-2">
                                <strong className="card-price">{trial? "Free" : `$${price}`}</strong>
                                {!trial && "/month"}
                            </div>

                            <p className="t-incl">This includes:</p>

                            <ul className="list-group list-group-flush mb-4">
                                <li className="list-group-item">
                                    <FontAwesomeIcon icon={faCheck}/>
                                    <span>{fb_pages_limit == "999" || fb_pages_limit == null ? "Unlimited pages" : `${fb_pages_limit} ${page_text}`}</span>
                                </li>
                                <li className="list-group-item">
                                    <FontAwesomeIcon icon={faCheck}/>
                                    <span>{sub_users_limit == "999" || sub_users_limit == 0 ? 'Unlimited users' : `1 account owner + ${sub_users_limit} ${sub_users_text}` }</span>
                                </li>

                                <li className="list-group-item">
                                    <FontAwesomeIcon icon={faCheck}/>
                                    <span>Unlimited AI-replies</span>
                                </li>

                                <li className="list-group-item">
                                    <FontAwesomeIcon icon={faCheck}/>
                                    <span>{comments_limit ? `Up to ${numberWithCommas(comments_limit)} comments` : 'Unlimited comments'}</span>
                                </li>



                                {/*<li className="list-group-item">*/}
                                {/*    <FontAwesomeIcon icon={faCheck}/>*/}
                                {/*    <span>{data_retention} days data retention</span>*/}
                                {/*</li>*/}
                                {/*<li className="list-group-item">*/}
                                {/*    <FontAwesomeIcon icon={faCheck}/>*/}
                                {/*    <span>All moderation filters</span>*/}
                                {/*</li>*/}
                                {/*<li className="list-group-item">*/}
                                {/*    <FontAwesomeIcon icon={faCheck}/>*/}
                                {/*    <span>Email support</span>*/}
                                {/*</li>*/}
                            </ul>

                            <div className="d-flex  pt-3">
                                <form method="POST" action="/create_checkout_session">
                                    <input type="hidden" name="authenticity_token" value={CSRF}/>
                                    <input type="hidden" name="level_id" value={level.id}/>
                                    <button className="btn btn-primary select-plan mb-3"
                                            type="submit">{selected ? (show_plans ? 'Checkout' : 'Your current plan') : 'Checkout'}</button>
                                </form>

                            </div>

                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default React.memo(PlanItem)