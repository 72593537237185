import React from 'react'
import ModalWindow from "../../ModalWindow"

export const DeleteModeModal = ({ onClose, onSubmit }) => {
  return (
    <ModalWindow
      hideClose={true}
      header="Are you sure?"
      closeHandler={onClose}
    >
      <div className="mb-4">
        Unlike hiding, deleting comments can't be reversed so use this function carefully!
        <div className="sent-text">Comments will be deleted with a random delay up to 10 seconds</div>
      </div>
      <div className="row">
        <form onSubmit={onSubmit}>
          <button type="submit" className="btn btn-danger float-end">
            Confirm
          </button>
          <button
            type="reset"
            className="btn btn-secondary float-end mx-2"
            onClick={onClose}
          >
            Cancel
          </button>
        </form>
      </div>
    </ModalWindow>
  )
} 